var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 bg-black-80 no-select z-11",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-column bg-adori-gray ph2 pv3 mh-95 min-w5 br3 shadow-2",
        },
        [
          _c("header", { staticClass: "flex flex-row justify-between ph2" }, [
            _c("div", { staticClass: "f4 white" }, [_vm._v("Tag Properties")]),
            _c(
              "button",
              {
                staticClass:
                  "bw0 bg-adori-gray light-gray link pointer dim right",
                on: {
                  click: function ($event) {
                    return _vm.handleCloseButtonClicked()
                  },
                },
              },
              [_c("i", { staticClass: "material-icons f4" }, [_vm._v("close")])]
            ),
          ]),
          _vm.isYoutube
            ? _c("div", [
                _c("div", { staticClass: "flex w-100 ph2 mt4" }, [
                  _c("div", { staticClass: "w-50 flex items-center pr3" }, [
                    _c("div", { staticClass: "w-50 white f6" }, [
                      _vm._v("Tag offset"),
                    ]),
                    _c("div", { staticClass: "w-50" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.millis,
                            expression: "millis",
                          },
                        ],
                        staticClass:
                          "bg-adori-very-light-gray br2 cus-pad gray white w-100 f6",
                        style: !_vm.isValidationForInput
                          ? "border: 1px solid red;"
                          : "border: 1px solid transparent;",
                        attrs: {
                          id: "tagEditTextArea2",
                          placeholder: "Enter Duration",
                          maxlength: "12",
                          autocomplete: "off",
                        },
                        domProps: { value: _vm.millis },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.millis = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-50 flex items-center pl3" },
                    [
                      _c("div", { staticClass: "w-50 white f6" }, [
                        _vm._v("Tag duration"),
                      ]),
                      _c(
                        "date-picker",
                        {
                          staticClass: "adori-date-picker w-100",
                          attrs: {
                            format: _vm.format,
                            "value-type": "format",
                            type: "time",
                            "time-select-options": _vm.timeSelectOptions,
                            placeholder: _vm.format.toLocaleUpperCase(),
                          },
                          on: {
                            focus: _vm.handleFocus,
                            clear: _vm.resetDuration,
                          },
                          model: {
                            value: _vm.duration,
                            callback: function ($$v) {
                              _vm.duration = $$v
                            },
                            expression: "duration",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons light-gray f4",
                              attrs: { slot: "calendar-icon" },
                              slot: "calendar-icon",
                            },
                            [_vm._v("schedule")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.isYoutube
            ? _c("div", [
                _c("div", { staticClass: "flex w-100 ph2 mt2" }, [
                  _c("div", { staticClass: "w-50 flex items-center pr3" }, [
                    _c("div", { staticClass: "w-50 white f6" }, [
                      _vm._v("Tag transition"),
                    ]),
                    _c("div", { staticClass: "w-50" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.transition,
                              expression: "transition",
                            },
                          ],
                          ref: "advertiser",
                          staticClass:
                            "db bg-adori-gray white ba b--gray br2 f6 cus-w h2",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.transition = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.transitionList, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "w-50 flex items-center pl3" }, [
                    _c("div", { staticClass: "w-50 white f6" }, [
                      _vm._v("Fit to Screen"),
                    ]),
                    _c("div", { staticClass: "w-50" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.fitToScreen,
                              expression: "fitToScreen",
                            },
                          ],
                          staticClass:
                            "db bg-adori-gray white ba b--gray br2 f6 cus-w h2 ml-40",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.fitToScreen = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.fitToScreenList, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.value } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.title) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.isWhitelisted
            ? _c("div", [
                _c("div", { staticClass: "w-100 mt4" }, [
                  _c(
                    "div",
                    { staticClass: "w-100 flex items-center ph2" },
                    [
                      _c("span", { staticClass: "f4 white fw5 mv3 mr3" }, [
                        _vm._v("Kenburns"),
                      ]),
                      _c("TheToggleButton", {
                        attrs: {
                          value: _vm.showTagEffect,
                          "checked-bg": "#de1a23",
                          height: "16",
                          width: "30",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showTagEffect = !_vm.showTagEffect
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex ph2 mt2 justify-between items-center",
                    },
                    [
                      _c("div", { staticClass: "white f6 mr3" }, [
                        _vm._v("Direction"),
                      ]),
                      _c("div", { staticClass: "w-50" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.kenBurnZoomDirection,
                                expression: "kenBurnZoomDirection",
                              },
                            ],
                            ref: "kenBurnZoomDirection",
                            staticClass:
                              "db bg-adori-light-gray white ba b--gray br2 f6 w-100 h2",
                            class: { "o-40 not-allowed": !_vm.showTagEffect },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.kenBurnZoomDirection = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(
                            _vm.kenBurnZoomDirectionList,
                            function (item, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  attrs: { disabled: !_vm.showTagEffect },
                                  domProps: { value: item },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex mt2 ph2 justify-between items-center",
                    },
                    [
                      _c("div", { staticClass: "white f6 mr3" }, [
                        _vm._v("Zoom Effect"),
                      ]),
                      _c("div", { staticClass: "w-50" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.kenBurnZoomEffectValue,
                                expression: "kenBurnZoomEffectValue",
                              },
                            ],
                            ref: "kenBurnZoomEffectValue",
                            staticClass:
                              "db bg-adori-light-gray white ba b--gray br2 f6 w-100 h2",
                            class: { "o-40 not-allowed": !_vm.showTagEffect },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.kenBurnZoomEffectValue = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.kenBurnZoomEffect, function (item, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                attrs: { disabled: !_vm.showTagEffect },
                                domProps: { value: item.value },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.title) +
                                    "\n              "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.isYoutube
            ? _c("div", [
                _c("div", { staticClass: "flex ph2 mt5 items-center" }, [
                  _c("div", { staticClass: "white f6 mr3" }, [
                    _vm._v("Apply same transition to all tags in this track"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.applyEffectToAllTags,
                        expression: "applyEffectToAllTags",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.applyEffectToAllTags)
                        ? _vm._i(_vm.applyEffectToAllTags, null) > -1
                        : _vm.applyEffectToAllTags,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.applyEffectToAllTags,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.applyEffectToAllTags = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.applyEffectToAllTags = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.applyEffectToAllTags = $$c
                        }
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _c(
            "footer",
            { staticClass: "flex justify-end ph1 mb2" },
            [
              _c("BaseButtonRed", {
                staticClass: "mt4 f2",
                attrs: {
                  loading: _vm.loading || _vm.isTagEffectFetching,
                  disabled:
                    _vm.loading ||
                    !_vm.isValidationForInput ||
                    _vm.isTagEffectFetching,
                  text: "Save",
                  onClick: _vm.addTagProperties,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }