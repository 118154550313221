


















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { msToSeconds, removeFormatting, removeMsFormatting, returnHHMMSS } from '@/utils/time'
import { isValidTime } from '@/utils/validation'
import TheToggleButton from '@/components/Common/TheToggleButton.vue'
import { computed, ref } from '@vue/composition-api'
import { tagQueryKeys, useGetTagEffect } from '@/hooks/tag'
import { QueryClient, useQueryClient } from 'vue-query'
@Component({
  components: {
    TheToggleButton,
  },
  setup(props: any) {
    const queryClient = useQueryClient()
    const tag = computed(() => props.tagPositions[props.index])
    const effectId = computed(() => tag.value.effectId)
    const isEffectId = computed(() => !!effectId.value)
    const fitToScreenData = computed(() => (tag.value.fitToScreen ? tag.value.fitToScreen : 'containblur'))
    const fitToScreen = ref(fitToScreenData.value)

    const { data: tagEffectsData, isFetching: isTagEffectFetching } = useGetTagEffect(effectId, {
      enabled: isEffectId,
    })
    return {
      queryClient,
      tag,
      effectId,
      isEffectId,
      isTagEffectFetching,
      tagEffectsData,
      fitToScreen,
    }
  },
})
export default class TagProperties extends Vue {
  @Prop(Function) openTagProperties!: () => void
  @Prop(String) audioUid!: string

  //   @Prop(Object) tag!: any
  @Prop() tagPositions!: any
  @Prop(Number) index!: number

  @Getter networkType!: any
  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any
  @Getter isYoutube!: boolean
  @Getter isTimeLineEdited!: boolean
  @Getter isWhitelisted!: boolean

  @Action updateAudioTag!: any
  @Action pushNotification!: any

  duration: string = ''
  applyEffectToAllTags = false
  transitionDuration: string = '01'
  loading = false
  transitionList = [
    'random',
    'fade',
    'fadeblack',
    'fadewhite',
    'distance',
    'wipeleft',
    'wiperight',
    'wipeup',
    'wipedown',
    'slideleft',
    'slideright',
    'slideup',
    'slidedown',
    'smoothleft',
    'smoothright',
    'smoothup',
    'smoothdown',
    'rectcrop',
    'circlecrop',
    'circleclose',
    'circleopen',
    'horzclose',
    'horzopen',
    'vertclose',
    'vertopen',
    'diagbl',
    'diagbr',
    'diagtl',
    'diagtr',
    'hlslice',
    'hrslice',
    'vuslice',
    'vdslice',
    'dissolve',
    'pixelize',
    'radial',
    'none',
  ]
  transition = this.transitionList[0]

  showTagEffect: boolean = false
  kenBurnZoomDirectionList = [
    'top',
    'left',
    'right',
    'bottom',
    'center',
    'topleft',
    'topright',
    'centerleft',
    'centerright',
    'bottomleft',
    'bottomright',
  ]
  kenBurnZoomDirection = this.kenBurnZoomDirectionList[7]
  kenBurnZoomEffect = [
    { value: 0.5, title: 'zoom in' },
    { value: -0.5, title: 'zoom out' },
  ]
  kenBurnZoomEffectValue = -0.5
  millis = ''

  queryClient!: QueryClient
  tag!: any
  effectId!: string
  isEffectId!: boolean
  isTagEffectFetching!: boolean
  tagEffectsData!: any
  fitToScreenList = [
    { title: 'Blur', value: 'containblur' },
    { title: 'Crop', value: 'cover' },
  ]
  fitToScreen!: string

  @Watch('tagEffectsData')
  handleEffect() {
    if (this.tagEffectsData !== undefined) {
      this.showTagEffect = true
      this.kenBurnZoomEffectValue = !!this.tagEffectsData.zoomValue ? this.tagEffectsData.zoomValue : 1
      this.kenBurnZoomDirection = !!this.tagEffectsData.zoomDirection ? this.tagEffectsData.zoomDirection : 'top'
    }
  }

  async mounted() {
    this.loading = true
    this.millis = msToSeconds(this.tag.offsetMillis)
    if (this.tag.durationMillis) {
      const audioTimeObj: any = returnHHMMSS(new Date(this.audio.durationMillis).toISOString().substr(11, 8))
      this.duration =
        audioTimeObj.minutes === 0
          ? this.tag.durationMillis > this.audio.durationMillis
            ? new Date(this.audio.durationMillis).toISOString().substr(17, 2)
            : new Date(this.tag.durationMillis).toISOString().substr(17, 2)
          : new Date(this.tag.durationMillis).toISOString().substr(14, 5)
      console.log(this.duration)
    } else {
      this.duration = '00:30'
    }

    this.tag.transition && (this.transition = this.tag.transition)
    this.tag.transitionDurationMillis &&
      (this.transitionDuration = new Date(this.tag.transitionDurationMillis).toISOString().substr(17, 2))
    if (this.isTimeLineEdited) {
      await this.$store.dispatch('uploadAudioTags', this.audioUid)
    }
    this.handleEffect()
    this.loading = false
  }

  get audio() {
    return this.$store.getters.audio(this.audioUid)
  }
  get isValidationForInput() {
    if (isValidTime(this.millis)) {
      return true
    }
    return false
  }

  resetDuration() {
    const audioTimeObj: any = returnHHMMSS(new Date(this.audio.durationMillis).toISOString().substr(11, 8))
    this.duration =
      audioTimeObj.minutes === 0
        ? new Date(this.tag.durationMillis).toISOString().substr(17, 2)
        : new Date(this.tag.durationMillis).toISOString().substr(14, 5)
  }

  get timeSelectOptions() {
    let audioTimeObj: any = returnHHMMSS(new Date(this.audio.durationMillis).toISOString().substr(11, 8))
    const limit = this.networkType === 'YOUTUBE_ENTERPRISE' ? 16 : 4
    let seconds
    const minutes =
      audioTimeObj.minutes === 0
        ? []
        : audioTimeObj.hours > 0
        ? Array.from(Array(limit).keys())
        : Array.from(Array(audioTimeObj.minutes > limit ? limit : audioTimeObj.minutes).keys())

    if (this.isYoutube) {
      seconds = audioTimeObj.minutes > 0 ? Array.from(Array(60).keys()) : Array.from(Array(audioTimeObj.seconds).keys())
    } else {
      seconds =
        audioTimeObj.minutes > 0
          ? Array.from(Array(60).keys()).filter((el) => el % 3 === 0)
          : Array.from(Array(audioTimeObj.seconds).keys()).filter((el) => el % 3 === 0)
    }

    return {
      hours: [],
      minutes,
      seconds,
    }
  }

  get format() {
    const audioTimeObj: any = returnHHMMSS(new Date(this.audio.durationMillis).toISOString().substr(11, 8))
    return audioTimeObj.minutes ? 'mm:ss' : 'ss'
  }

  convertToNumber() {}
  handleCloseButtonClicked() {
    this.openTagProperties()
  }

  handleFocus(event: any) {
    event.target.blur()
  }

  async addTagProperties() {
    // we need to call api for changing duration
    const validateDuration = removeFormatting(this.duration)

    this.loading = true

    if (this.isYoutube && validateDuration < 1000) {
      this.pushNotification({
        text: 'Your duration cannot be less than 1 seconds',
        type: 'ERROR',
      })
      this.loading = false
      return
    } else if (!this.isYoutube && validateDuration < 3000) {
      this.pushNotification({
        text: 'Your duration cannot be less than 3 seconds',
        type: 'ERROR',
      })
      this.loading = false
      return
    }

    // if (
    //   this.ytModalId &&
    //   validateDuration > this.selectedEpisodeSettings[this.ytModalId].endTimeSec * 1000 - this.tag.offsetMillis
    // ) {
    //   this.pushNotification({
    //     text: 'Your duration cannot exceed audio length',
    //     type: 'ERROR',
    //   })
    //   this.loading = false
    //   return
    // }

    const payload: any = {
      id: this.tag.id,
      tagId: this.tag.tagId,
      durationMillis: removeFormatting(this.duration),
      offsetMillis: removeMsFormatting(this.millis),
    }
    if (this.isYoutube) {
      payload['transition'] = this.transition
      payload['transitionDurationMillis'] = removeFormatting(this.transitionDuration)
      payload['fitToScreen'] = this.fitToScreen
    }

    this.$gtag.event('tag-properties', payload)

    try {
      if (
        (this.showTagEffect && this.isYoutube && !this.isEffectId) ||
        (this.showTagEffect && this.isYoutube && this.isEffectId && !this.applyEffectToAllTags)
      ) {
        const effects = await this.$store.dispatch('createTagEffect', {
          zoomDirection: this.kenBurnZoomDirection,
          zoomValue: this.kenBurnZoomEffectValue,
          //   zoomDuration: removeFormatting(this.duration) / 1000,
          zoomDuration: 5,
        })
        this.queryClient.invalidateQueries([tagQueryKeys.TAG_EFFECTS, effects.id])
        payload['effectId'] = effects.id
      } else if (this.showTagEffect && this.isEffectId) {
        const effects = await this.$store.dispatch('updateTagEffect', {
          effectId: this.effectId,
          data: {
            zoomDirection: this.kenBurnZoomDirection,
            zoomValue: this.kenBurnZoomEffectValue,
            // zoomDuration: removeFormatting(this.duration) / 1000,
            zoomDuration: 5,
          },
        })
        this.queryClient.invalidateQueries([tagQueryKeys.TAG_EFFECTS, effects.id])
        payload['effectId'] = effects.id
      } else if (!this.showTagEffect) {
        payload['effectId'] = null
      }

      payload['applyEffectToAllTags'] = this.applyEffectToAllTags

      await this.updateAudioTag({
        audioUid: this.audioUid,
        payload,
      })
      await this.$store.dispatch('getAudioTags', this.audioUid)
      this.pushNotification({
        text: 'Changes applied',
        type: 'SUCCESS',
      })
      this.openTagProperties()
    } catch (e) {
      this.loading = false
    }
  }

  destroyed() {
    this.$store.commit('clearTagEdit')
  }
}
