
















































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import get from 'lodash.get'
import draggable from 'vuedraggable'
import AdoriTag from '@/components/Tags/AdoriTag.vue'
import { getTimelinePosition, removeMsFormatting } from '@/utils/time'
import { Action, Getter } from 'vuex-class'
import { RESOURCES, ACTIONS } from '@/constants/permissions'

// components
import { TagProvider } from '@/components/Tags/tags'
import TagProperties from '@/components/Tags/Modals/TagProperties.vue'

//enum
import { orientationStatus } from '@/components/Publish/publish'

import AdoriService from '@/services/adori'

@Component({
  components: { AdoriTag, draggable, TagProperties },
})
export default class AudioTagger extends Vue {
  @Prop(String) audioUid!: string
  @Prop(Array) tagPositions!: any[]
  @Prop(Array) audiogramPositions!: any[]
  @Prop() audioAdsByAudio!: any
  @Prop(Number) currentTime!: number
  @Prop(String) orientation!: string

  @Getter userId!: string
  @Getter audiosByUid!: any
  @Getter tag!: any
  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any
  @Getter selectedFile!: any
  @Getter recentTagId!: any
  @Getter audiogramById!: any
  @Getter audiogramByIdOnTimeline!: any
  @Getter timelineLoader!: boolean
  @Getter showLoaderText!: boolean
  @Getter timeLineZoom!: any
  @Getter isYoutube!: Boolean
  @Getter networkId!: string
  @Getter selectedProduct!: string
  @Getter isWhitelisted!: boolean

  @Action getOneAudiogram!: any
  @Action publishRssFeed!: any

  scrollRight = false
  scrollLeft = false
  scrollTimer: any = null

  height = 200
  timestampHeight = 20
  gap = 120
  draggingTagPosition: any = {}

  currentWindowY: number = 0
  currentClientY: number = 0
  hover: boolean = true

  showTagProperties: boolean = false

  currentItemIndex = 0
  startTimeSec = 0
  endTimeSec = this.seconds

  mounted() {
    if (this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]) {
      this.startTimeSec = this.selectedEpisodeSettings[this.ytModalId].startTimeSec
      this.endTimeSec = this.selectedEpisodeSettings[this.ytModalId].endTimeSec
    }

    document.getElementById('audioTaggerEl')?.addEventListener('scroll', (event: any) => {
      if (event.target.scrollLeft < Math.ceil((this.startTimeSec * this.gap) / this.timeLineZoom))
        event.target.scrollLeft = Math.ceil((this.startTimeSec * this.gap) / this.timeLineZoom)
    })
  }

  destroyed() {
    document.getElementById('audioTaggerEl')?.removeEventListener('scroll', () => {})
  }

  audioAdType(id: string) {
    return TagProvider.PROGRAMMATIC
  }

  get isTimeTrackTimelineLoader() {
    return this.audioUid === sessionStorage.getItem('timelineLoader')
  }
  get dragOptions() {
    return {
      group: {
        name: 'audioTagger',
        pull: false,
        put: ['tagsGrid', 'audioGramGrid'],
      },
      forceFallback: true,
      handle: '.drag-handle',
      chosenClass: 'tag-chosen',
      ghostClass: 'tag-ghost',
    }
  }

  getTagShadowWidth(xOffset: number) {
    const end = xOffset + 10 * this.gap
    const durationEnd = this.getXOffset(this.audio.durationMillis)
    if (end > durationEnd) {
      return durationEnd - xOffset
    }
    return end - xOffset
  }

  onMove(e: any, ev: any) {
    document.addEventListener('mousemove', this.mousePositionHandler)
    this.draggingTagPosition = {
      // A div containing svgs is at index 0!
      audioUid: this.audioUid,
      index: this.tagPositions.findIndex((obj: any) => obj.id === e.dragged.dataset.key),
      toPosition: getTimelinePosition(ev.clientX, this.timeLineZoom),
    }
    return false
  }

  updateMillis(millis: string, tagId: string, index: number) {
    if (this.audio.durationMillis > removeMsFormatting(millis)) {
      const tagDetails: any = this.tag(tagId)
      this.$store.dispatch('moveAudioTag', {
        audioUid: this.audioUid,
        index: index,
        toPosition: removeMsFormatting(millis),
        action: tagDetails.actions,
      })
    }
  }

  durationOffset(currentTag: any, nextTag: any) {
    if (nextTag) {
      const durationMillis = nextTag.offsetMillis - currentTag.offsetMillis
      return currentTag.durationMillis >= durationMillis
        ? (durationMillis / 1000 / this.timeLineZoom) * 120
        : (currentTag.durationMillis / 1000 / this.timeLineZoom) * 120
    }

    const audioDurationMillis = this.seconds * 1000 - currentTag.offsetMillis

    return currentTag.durationMillis >= audioDurationMillis
      ? (audioDurationMillis / 1000 / this.timeLineZoom) * 120
      : (currentTag.durationMillis / 1000 / this.timeLineZoom) * 120
  }

  mousePositionHandler(event: any) {
    this.currentWindowY = window.scrollY
    this.currentClientY = event.clientY
  }

  createTag(event: any) {
    const millis = getTimelinePosition(event.clientX, this.timeLineZoom)
    this.$store.dispatch('newTagOpenFrom', this.orientation)
    this.$store.dispatch('showTagEdit')
    this.$store.commit('setTimelinePosition', millis)
  }

  dropFile(event: any) {
    this.$store.commit('setTimelineLoader', true)
    this.$store.commit('setSelectedFile', event.dataTransfer.files[0])
    const type = get(this.selectedFile, 'type', '')
    if (!type.includes('image') && !type.includes('video/mp4')) {
      this.$store.dispatch('pushNotification', {
        text: 'Please choose an image or mp4 file',
        type: 'ERROR',
      })
      this.$store.commit('setTimelineLoader', false)
      return
    }

    if (type.includes('video/mp4') && this.bytesToMegaBytes(this.selectedFile.size) > 60) {
      this.$store.dispatch('pushNotification', {
        text: 'Please enter file lesser than 60MB',
        type: 'WARNING',
      })
      this.$store.commit('setTimelineLoader', false)
      return
    }

    const endWidth = this.getTagShadowWidth(this.getXOffset(getTimelinePosition(event.clientX, this.timeLineZoom)))

    if (endWidth > 0) {
      const reader: any = new FileReader()
      reader.onload = async () => {
        if (type.includes('video/mp4')) {
          this.$store.dispatch('changeTagVideo', this.selectedFile)
        } else {
          this.$store.dispatch('changeTagImage', reader.result)
        }

        await this.$store.dispatch('uploadTag', false)
        const tagItem = {
          tagId: this.recentTagId,
          offsetMillis: getTimelinePosition(event.clientX, this.timeLineZoom),
        }
        this.$emit('place-tag', tagItem)
        this.$store.commit('setTimelineLoader', false)
      }
      reader.readAsDataURL(this.selectedFile)
    }
  }

  bytesToMegaBytes(bytes: any) {
    return bytes / (1024 * 1024)
  }

  async handleDroppedTag() {
    document.removeEventListener('mousemove', this.mousePositionHandler)
    let theSum = this.currentWindowY + this.currentClientY

    const at: any = document.getElementById('audioTaggerEl')
    let boundBox = at.getBoundingClientRect()
    const endWidth = this.getTagShadowWidth(this.getXOffset(this.draggingTagPosition.toPosition))
    if (endWidth > 0) {
      this.$store.dispatch('moveAudioTag', this.draggingTagPosition)
      this.$store.commit('setTimelineEdited', true)
    }
    this.currentWindowY = 0
    this.currentClientY = 0
  }

  toggleHover() {
    this.hover = !this.hover
  }

  get audio() {
    return this.$store.getters.audio(this.audioUid)
  }

  get audioCollectionUids() {
    let feedUids = []
    if (this.audio && this.audio.audioCollections) {
      feedUids = this.audio.audioCollections.map((audioCol: any) => audioCol.uid)
    }
    return feedUids
  }

  get gapPerSecond() {
    return this.gap / this.timeLineZoom
  }

  get seconds() {
    return this.audio.durationMillis / 1000
  }

  get width() {
    let width =
      ((this.ytModalId && this.endTimeSec ? this.endTimeSec : this.seconds) * this.gap) / this.timeLineZoom + 25
    return width
  }

  getTagStyle(offset: number, index: number, orientation: string) {
    if (orientation === orientationStatus.LANDSCAPE) {
      return {
        top: '65px',
        left: offset - 5 + 'px',
        zIndex: '1',
        '--tagColor': this.getPreviousTagOffset(index) + 'px',
      }
    }
    if (orientation === orientationStatus.SQUARE) {
      return {
        top: '58px',
        left: offset + 20 + 'px',
        zIndex: '1',
        '--tagColor': this.getPreviousTagOffset(index) + 'px',
      }
    }
    if (orientation === orientationStatus.PORTRAIT) {
      return {
        top: '48px',
        left: offset + 42 + 'px',
        zIndex: '1',
        '--tagColor': this.getPreviousTagOffset(index) + 'px',
      }
    }
  }

  getTagStyleSmall(offset: number) {
    return {
      top: '70px',
      left: offset + 60 + 'px',
      zIndex: '1',
    }
  }
  getAudiogramStyleSmall(offset: number, orientation: string) {
    if (orientation === orientationStatus.LANDSCAPE) {
      return {
        top: '75px',
        left: offset + 20 + 'px',
        zIndex: '1',
      }
    }
    if (orientation === orientationStatus.SQUARE) {
      return {
        top: '67px',
        left: offset + 20 + 'px',
        zIndex: '1',
      }
    }
    if (orientation === orientationStatus.PORTRAIT) {
      return {
        top: '50px',
        left: offset + 20 + 'px',
        zIndex: '1',
      }
    }
  }

  getTime(x: number) {
    let totalSeconds = (x / this.gap) * this.timeLineZoom
    let minute = '00' + Math.floor(totalSeconds / 60)
    let sec = '00' + (totalSeconds % 60)
    return minute.substring(minute.length - 2) + ':' + sec.substring(sec.length - 2)
  }

  get verticalLinePoints() {
    // a line is drawn every three seconds
    const numberOfLines = Math.ceil(this.seconds / this.timeLineZoom)
    const points = Array(numberOfLines)
      .fill(1)
      .map((e, index) => index * this.gap + (this.isYoutube ? 0 : this.gapPerSecond))
      .slice(this.ytModalId && this.startTimeSec ? Math.ceil(this.startTimeSec / 3) : 0)

    !this.isYoutube && points.unshift(0)

    return points
  }

  getXOffset(offsetMillis: number) {
    return offsetMillis === 0 ? 0 : (offsetMillis * this.gap) / (this.timeLineZoom * 1000) - 90
  }

  getAdTimelinePositions(timeInMillis: number) {
    return (timeInMillis * this.gap) / (this.timeLineZoom * 1000)
  }

  getPreviousTagOffset(index: number) {
    if (this.tagXOffsets.length > 1) {
      const offsetValue = get(this.tagXOffsets[index + 1], 'offset') - get(this.tagXOffsets[index], 'offset')
      if (offsetValue && offsetValue !== NaN) {
        return offsetValue + 75
      } else {
        return 0
      }
    }
  }

  get tagXOffsets() {
    let tagPositions = this.tagPositions.map(
      (e: { [key: string]: any }, index: number) => ({
        id: e.id,
        tagId: e.tagId,
        tag: e.tag,
        offset: this.getXOffset(e.offsetMillis),
        action: e.tag.actions,
        millis: e.offsetMillis,
        offsetMillis: e.offsetMillis,
        fitToScreen: e.fitToScreen,
        durationMillis: e.durationMillis,
        transition: e.transition || null,
        transitionDurationMillis: e.transitionDurationMillis || null,
        index: index,
        orientation: e.tag.orientation,
        effectId: e.effectId,
      }),
      []
    )

    this.ytModalId &&
      (tagPositions = tagPositions.filter(
        (obj: any) => obj.offsetMillis / 1000 >= this.startTimeSec && obj.offsetMillis / 1000 < this.endTimeSec
      ))

    return tagPositions
  }

  get audiogramXOffsets() {
    return this.audiogramPositions.map((obj: any, index) => ({
      id: obj.id,
      audiogramId: obj.audiogramId,
      offset: this.getXOffset(obj.offsetMillis),
      millis: obj.offsetMillis,
      durationMillis: obj.durationMillis,
      index: index,
    }))
  }

  get tagIds() {
    return this.tagPositions.map((p) => p.tagId)
  }

  getTagById(tagId: string) {
    return this.tagPositions.filter((p) => p.tagId === tagId)
  }

  findIndexForTag(tagId: string) {
    return this.tagPositions.findIndex((x) => x.tagId === tagId)
  }

  get currentPosition() {
    return (this.currentTime * this.gap) / this.timeLineZoom
  }

  offsetToMillis(offset: number) {
    // return Math.floor((offset / this.gap) * 3 + 3) * 1000
    return offset === 0 ? 0 : ((offset + 90) * (this.timeLineZoom * 1000)) / this.gap
  }

  placeTag(item: any, xRelative: number) {
    const toPosition = this.offsetToMillis(item.offset + xRelative)
    this.$emit('move-tag', {
      index: this.tagXOffsets.indexOf(item),
      toPosition,
    })
  }

  async removeTag(tagOffset: any) {
    this.$gtag.event('remove-tag', { action: 'removed from timeline' })
    this.$store.dispatch('removeAudioTag', {
      audioUid: this.audioUid,
      tagPosition: {
        ...tagOffset,
        offsetMillis: this.offsetToMillis(tagOffset.offset),
      },
    })
    if (this.selectedProduct === 'BLOG' && !this.isWhitelisted) {
      const tagId = tagOffset.tagId
      await AdoriService.deleteTag(this.networkId, tagId)
      this.$store.commit('deleteTag', tagId)
      this.$store.dispatch('deleteTagId', tagId)
      this.$store.dispatch('deleteTagFromTagCollections', tagId)
    }
    const feedId = get(this.audiosByUid[this.audioUid], 'audioCollections[0].uid')
    if (feedId) {
      await this.publishRssFeed({
        rssFeedUid: feedId,
      })
    }
    this.$store.commit('setTimelineEdited', true)
  }
  async removeAudiogram(audiogram: any) {
    this.$gtag.event('remove-audiogram', { action: 'removed from timeline' })
    this.$store.dispatch('removeAudioAudiogram', {
      audioUid: this.audioUid,
      audiogramPosition: {
        ...audiogram,
        offsetMillis: this.offsetToMillis(audiogram.offset),
      },
    })
  }

  editAudiogram(audiogramId: string) {
    this.$store.dispatch('showAudiogramEdit', audiogramId)
  }

  editTag(tagId: string, orientation: string) {
    if (orientation) this.$store.dispatch('newTagOpenFrom', orientation)
    this.$store.dispatch('showTagEdit', tagId)
  }

  editTagProperties(index?: number) {
    if (index !== undefined) this.currentItemIndex = index
    this.showTagProperties = !this.showTagProperties
  }

  get isInsertTagEpisodeAllowed() {
    if (this.audioCollectionUids.length > 0) {
      for (const feedUid of this.audioCollectionUids) {
        if (this.$permissions.isInsertTagEpisodeShowAllowed(feedUid)) {
          return true
        }
      }
      return false
    } else {
      return this.$permissions.isInsertTagEpisodeAllowed()
    }
  }

  isEditTagInShowsAllowed(tagId: String) {
    const tag = this.tag(tagId)
    const feedUids = get(tag, 'tag.feedUids', [])
    if (!feedUids) {
      if (get(tag, 'tag,userId') === this.userId) {
        return true
      } else {
        return false
      }
    }
    return this.$permissions.validateNetworkShowPermissionsForShows(feedUids, RESOURCES.tag, ACTIONS.edit)
  }

  isEditDurationAllowed(tagId: String) {
    const tag = this.tag(tagId)
    if (tag && tag.actions === 'audio') {
      return false
    }
    return true
  }

  @Watch('scrollRight')
  handleRightScroll() {
    const audioTagger: any = document.getElementById('audioTaggerEl')
    if (this.scrollRight) {
      this.scrollTimer = setInterval(() => {
        if (this.currentPosition < (((this.audio.durationMillis - 1000) / 1000) * this.gap) / this.timeLineZoom) {
          audioTagger.scrollLeft += 40
          this.$emit('scroll-timeline', this.currentTime + 1)
        } else {
          this.scrollRight = false
          clearInterval(this.scrollTimer)
        }
      }, 100)
    } else {
      clearInterval(this.scrollTimer)
    }
  }
  @Watch('scrollLeft')
  handleLeftScroll() {
    const audioTagger: any = document.getElementById('audioTaggerEl')
    if (this.scrollLeft) {
      this.scrollTimer = setInterval(() => {
        if (this.currentPosition <= 0) {
          this.scrollLeft = false
          clearInterval(this.scrollTimer)
        } else {
          audioTagger.scrollLeft -= 40
          this.$emit('scroll-timeline', this.currentTime - 1)
        }
      }, 100)
    } else {
      clearInterval(this.scrollTimer)
    }
  }

  moveTimelineBar(event: any) {
    this.$store.commit('setTimelineScroll', false)
    document.ontouchmove = (event) => {
      const e = event.touches[0]
      this.scrollRight = e.clientX > window.innerWidth - 80 ? true : false
      this.scrollLeft = e.clientX < 180 ? true : false

      this.$emit(
        'scroll-timeline',
        getTimelinePosition(e.clientX, this.timeLineZoom) / 1000 >= 0
          ? getTimelinePosition(e.clientX, this.timeLineZoom) / 1000
          : 0
      )
    }

    document.onmousemove = (e) => {
      this.scrollRight = e.clientX > window.innerWidth - 80 ? true : false
      this.scrollLeft = e.clientX < 180 ? true : false

      this.$emit(
        'scroll-timeline',
        getTimelinePosition(e.clientX, this.timeLineZoom) / 1000 >= 0
          ? getTimelinePosition(e.clientX, this.timeLineZoom) / 1000
          : 0
      )
    }

    document.ontouchend = (e) => {
      this.$store.commit('setTimelineScroll', true)
      this.scrollLeft = false
      this.scrollRight = false
      document.ontouchmove = null
      document.ontouchend = null
    }

    document.onmouseup = () => {
      this.$store.commit('setTimelineScroll', true)
      this.scrollLeft = false
      this.scrollRight = false
      document.onmousemove = null
      document.onmouseup = null
    }
  }
}
