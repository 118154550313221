var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _vm.selectedProduct === "VIDEO" &&
          (!_vm.isFromYoutube || _vm.isFromHomePage)
            ? _c("TheSideNavigation")
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "scrollbar",
              class:
                _vm.isFromYoutube && !_vm.isFromHomePage
                  ? "limiter-y"
                  : "limiter",
            },
            [
              _vm.isFromYoutube && !_vm.isFromHomePage
                ? _c(
                    "div",
                    { staticClass: "flex justify-right" },
                    [
                      _c("BaseButtonRed", {
                        staticClass: "mt3",
                        attrs: {
                          text: "Done",
                          onClick: _vm.nextYoutube,
                          loading: _vm.isAutomationLoading,
                          disabled: _vm.isAutomationLoading,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "TheTitle",
                {
                  class: {
                    topMargin: _vm.isFromYoutube && !_vm.isFromHomePage,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "absolute top-5-2",
                      class: {
                        topShift: _vm.isFromYoutube && !_vm.isFromHomePage,
                      },
                      attrs: { slot: "left" },
                      slot: "left",
                    },
                    [
                      _c("div", { staticClass: "flex items-center mb1" }, [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isAutomationLoading,
                                expression: "!isAutomationLoading",
                              },
                            ],
                            staticClass:
                              "material-icons f3 light-gray mr4 v-btm pointer dim",
                            on: {
                              click: function ($event) {
                                return _vm.$router.go(-1)
                              },
                            },
                          },
                          [_vm._v("arrow_back")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-center items-center mr3 h2 br1",
                            staticStyle: {
                              width: "57.14px",
                              background: "black",
                            },
                          },
                          [
                            _vm.audioPreviewImage
                              ? _c("img", {
                                  staticClass: "h2",
                                  attrs: { src: _vm.audioPreviewImage },
                                })
                              : _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f5 bg-adori-very-light-gray br2 tc pv2",
                                    staticStyle: { width: "57.14px" },
                                  },
                                  [_vm._v("audiotrack")]
                                ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "f3 truncate light-gray fw1",
                            staticStyle: { "max-width": "325px" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.audioName) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      attrs: { slot: "right" },
                      slot: "right",
                    },
                    [
                      _vm.audioName && _vm.isPublishPodcastAllowed
                        ? _c("PublishButton", {
                            staticClass: "mt3",
                            attrs: {
                              audio: _vm.audioData,
                              disabledButton:
                                _vm.audioStitchTaskStatus &&
                                _vm.audioStitchTaskStatus !== "FINISHED",
                              audioUid: _vm.trackUid,
                              fromYoutube: _vm.isFromYoutube,
                            },
                            on: { "replace-audio": _vm.handleReplaceAudio },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm.audioStitchTaskStatus &&
              _vm.audioStitchTaskStatus !== "FINISHED"
                ? _c("div", { staticClass: "mt5" }, [
                    _c("div", { staticClass: "f4 light-gray mb4 ml4" }, [
                      _vm._v(
                        "\n          Ads are being inserted into your audio. This may take a few minutes...\n        "
                      ),
                    ]),
                  ])
                : _vm.audioIsGettingReplaced
                ? _c("div", { staticClass: "mt5" }, [
                    _c("div", { staticClass: "f4 light-gray mb4 ml4" }, [
                      _vm._v(
                        "This audio is being replaced. This may take a few minutes..."
                      ),
                    ]),
                  ])
                : _c("div", [
                    _c(
                      "div",
                      {
                        class: {
                          topSticky: _vm.isSticky,
                        },
                      },
                      [
                        _vm.isAudioLoading ||
                        _vm.isTrackTagsLoading ||
                        _vm.isTrackAudiogramsLoading
                          ? _c("div", { staticClass: "flex flex-wrap ml3" }, [
                              _c("div", {
                                staticClass:
                                  "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center mr3 mb3 br2 skeleton timelineLoader-height",
                              }),
                            ])
                          : _c(
                              "transition",
                              { attrs: { name: "fadeHeight", mode: "out-in" } },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isMaximized,
                                        expression: "isMaximized",
                                      },
                                    ],
                                  },
                                  [
                                    _vm.audioName
                                      ? _c("AudioTagger", {
                                          attrs: {
                                            audioUid: _vm.trackUid,
                                            tagPositions:
                                              _vm.tagPositions || [],
                                            audiogramPositions:
                                              _vm.audiogramPositions || [],
                                            currentTime: _vm.currentTime,
                                            orientation: _vm.orientation,
                                          },
                                          on: {
                                            "place-tag": _vm.placeTag,
                                            "scroll-timeline": function (
                                              $event
                                            ) {
                                              return _vm.onCurrentTime($event)
                                            },
                                            "drag-start": function ($event) {
                                              _vm.isPlaying = false
                                            },
                                          },
                                          nativeOn: {
                                            scroll: function ($event) {
                                              return _vm.onAudioTaggerScroll.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.audioName
                                      ? _c("NewAudioPlayer", {
                                          attrs: {
                                            currentTime: _vm.currentTime,
                                            audioUid: _vm.trackUid,
                                            tagsInTrack: _vm.tagPositions || [],
                                            isPlaying: _vm.isPlaying,
                                          },
                                          on: {
                                            "scroll-timeline": function (
                                              $event
                                            ) {
                                              return _vm.onCurrentTime($event)
                                            },
                                            "toggle-play": function ($event) {
                                              _vm.isPlaying = !_vm.isPlaying
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                        _c("div", {
                          attrs: { id: "audio_timeline_tour_step_1" },
                        }),
                        _c(
                          "div",
                          { staticClass: "bb b--dark-gray bw1 mt--16" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "minimize-div",
                                attrs: { id: "audio_timeline_tour_step_3" },
                              },
                              [
                                _vm.isMaximized
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "min",
                                        on: {
                                          click: function ($event) {
                                            _vm.isMaximized = !_vm.isMaximized
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons v-mid white flex justify-center",
                                          },
                                          [_vm._v(" arrow_drop_up ")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.isMaximized
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "min",
                                        on: {
                                          click: function ($event) {
                                            _vm.isMaximized = !_vm.isMaximized
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons v-mid white flex justify-center",
                                          },
                                          [_vm._v(" arrow_drop_down ")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-neg" },
                              [
                                _vm.isAdvanced
                                  ? _c("BaseButtonTab", {
                                      attrs: {
                                        text: "Interactive tags",
                                        active: _vm.currentTab === "TAGS",
                                        onClick: function () {
                                          _vm.currentTab = "TAGS"
                                          _vm.selectCollection("TAGS_IN_TRACK")
                                        },
                                        disabled: _vm.isAutomationLoading,
                                      },
                                    })
                                  : _vm._e(),
                                _c("BaseButtonTab", {
                                  staticClass: "ml3",
                                  attrs: {
                                    id: "audio_timeline_tour_step_4",
                                    text: "AI tags",
                                    active: _vm.currentTab === "SUGGESTED",
                                    onClick: function () {
                                      _vm.currentTab = "SUGGESTED"
                                      _vm.selectCollection("TAG_SUGGESTIONS")
                                    },
                                  },
                                }),
                                _vm.isAdvanced && !_vm.backgroundVideo
                                  ? _c("BaseButtonTab", {
                                      staticClass: "ml3",
                                      attrs: {
                                        text: "Audiograms",
                                        active: _vm.currentTab === "AUDIOGRAM",
                                        onClick: function () {
                                          _vm.currentTab = "AUDIOGRAM"
                                          _vm.selectCollection("AUDIOGRAM")
                                        },
                                        disabled: _vm.isAutomationLoading,
                                      },
                                    })
                                  : _vm._e(),
                                _c("BaseButtonTab", {
                                  staticClass: "ml3",
                                  attrs: {
                                    text: "Subtitles",
                                    active: _vm.currentTab === "SUBTITLE",
                                    onClick: function () {
                                      _vm.currentTab = "SUBTITLE"
                                      _vm.selectCollection("SUBTITLE")
                                    },
                                    disabled: _vm.isAutomationLoading,
                                  },
                                }),
                                _c("BaseButtonTab", {
                                  staticClass: "ml3",
                                  attrs: {
                                    text: "Video Preview",
                                    active: _vm.currentTab === "PREVIEW",
                                    onClick: function () {
                                      _vm.currentTab = "PREVIEW"
                                      _vm.selectCollection("PREVIEW")
                                    },
                                    disabled: _vm.isAutomationLoading,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm.currentTab === "TAGS"
                          ? _c("TheTagCollections", {
                              attrs: {
                                currentCollection: _vm.currentCollection,
                                selectCollection: _vm.selectCollection,
                                tagsInTrack: _vm.tagPositions,
                                filter: _vm.orientation,
                                showTagsInTrack: "",
                                showAllTags: "",
                                showTagSuggestions: "",
                              },
                              on: { setFilter: _vm.setFilter },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", [
                      [
                        "TAGS",
                        "SUGGESTED",
                        "AUDIOGRAM",
                        "SUBTITLE",
                        "PREVIEW",
                      ].includes(_vm.currentTab)
                        ? _c(
                            "div",
                            { staticClass: "flex flex-wrap justify-left" },
                            [
                              _vm.currentTab === "TAGS" &&
                              _vm.isAdvanced &&
                              !_vm.isTrackTagsLoading
                                ? _c("TheTagsGrid", {
                                    attrs: {
                                      currentCollection: _vm.currentCollection,
                                      tagsInTrack: _vm.tagPositions,
                                      tagIsDraggable:
                                        _vm.isInsertTagEpisodeAllowed,
                                      orientation: _vm.orientation,
                                      showHeader: "",
                                    },
                                    on: {
                                      "place-tag": _vm.placeTag,
                                      "drag-started": function ($event) {
                                        _vm.isPlaying = false
                                      },
                                      "change-tab": _vm.changeTab,
                                    },
                                  })
                                : _vm._e(),
                              _vm.currentTab === "SUGGESTED"
                                ? _c("TheTagSuggestions", {
                                    attrs: {
                                      filter: _vm.filter,
                                      audioUid: _vm.audioData.uid,
                                      playSnippet: _vm.playSnippet,
                                      pauseSnippet: _vm.pauseSnippet,
                                      currentTime: _vm.currentTime,
                                      onCurrentTime: _vm.onCurrentTime,
                                      isMaximized: _vm.isMaximized,
                                    },
                                    on: {
                                      "scroll-timeline": function ($event) {
                                        return _vm.onCurrentTime($event)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.currentTab === "AUDIOGRAM"
                                ? _c("TheAudiogramGrid", {
                                    attrs: {
                                      audioUid: _vm.audioData.uid,
                                      audiogramInTrack: _vm.audiogramPositions,
                                      orientation: _vm.orientation,
                                    },
                                    on: {
                                      setFilter: _vm.setFilter,
                                      "drag-started": function ($event) {
                                        _vm.isPlaying = false
                                      },
                                      "place-audiogram": _vm.placeAudiogram,
                                    },
                                  })
                                : _vm._e(),
                              _vm.currentTab === "SUBTITLE"
                                ? _c("TheSubtitlePanel", {
                                    attrs: {
                                      currentTime: _vm.currentTime,
                                      isMaximized: _vm.isMaximized,
                                      trackUid: _vm.audioData.uid,
                                      tagsInTrack: _vm.tagPositions,
                                      coverImage: _vm.audioPreviewImage,
                                      isPlaying: _vm.isPlaying,
                                    },
                                    on: {
                                      "toggle-play": function ($event) {
                                        _vm.isPlaying = !_vm.isPlaying
                                      },
                                      "scroll-timeline": function ($event) {
                                        return _vm.onCurrentTime($event)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.currentTab === "PREVIEW"
                                ? _c("TheVideoPreview", {
                                    attrs: {
                                      currentTime: _vm.currentTime,
                                      isMaximized: _vm.isMaximized,
                                      trackUid: _vm.audioData.uid,
                                      backgroundVideo: _vm.backgroundVideo,
                                      audiogramPositions:
                                        _vm.audiogramPositions || [],
                                      tagsInTrack: _vm.tagPositions,
                                      coverImage: _vm.audioPreviewImage,
                                      isPlaying: _vm.isPlaying,
                                    },
                                    on: {
                                      "toggle-play": function ($event) {
                                        _vm.isPlaying = !_vm.isPlaying
                                      },
                                      "scroll-timeline": function ($event) {
                                        return _vm.onCurrentTime($event)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentTab === "TAGS" && _vm.isTrackTagsLoading
                        ? _c(
                            "div",
                            { staticClass: "flex flex-wrap ml3" },
                            _vm._l(new Array(50), function (_, index) {
                              return _c("div", {
                                key: index,
                                staticClass:
                                  "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center mr3 mb3 br2 skeleton",
                                class: {
                                  "landscape-size": _vm.filter === "LANDSCAPE",
                                  "square-size": _vm.filter === "SQUARE",
                                  "portrait-size": _vm.filter === "PORTRAIT",
                                },
                              })
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }