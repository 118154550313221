



























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { fabric } from 'fabric'
import { Action, Getter } from 'vuex-class'
import { getTemplateStyle } from '@/components/Subtitle/utils'
import { sliderOPtions } from '@/components/Subtitle/constants'
import VueSlider from 'vue-slider-component'
import { secondsToMmss } from '@/utils/time'
import get from 'lodash.get'
import { useGetAudiogramFonts } from '@/hooks/audiogram'

@Component({
  components: { VueSlider },
  setup() {
    useGetAudiogramFonts()
  },
})
export default class SubtitlePreview extends Vue {
  @Prop(Boolean) isMaximized!: boolean
  @Prop(Number) currentTime!: number
  @Prop(Boolean) isPlaying!: boolean
  @Prop(String) backgroundVideo!: string
  @Prop(Array) tagsInTrack!: any[]
  @Prop(String) coverImage!: string
  @Prop(String) trackUid!: string
  @Prop(String) audiogramImg!: string
  @Prop(String) audiogramVideo!: string
  @Prop(String) isStepTwo!: boolean

  @Getter fontsList!: any
  @Getter subtitleTemplates!: any
  @Getter subtitleStyleIds!: any
  @Getter selectedTemplate!: any
  @Getter subtitlesList!: any

  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any

  @Action showIframeEmbed!: any

  orientation = 'LANDSCAPE'
  canvasContext!: any
  artBoardCtx!: any
  videoE!: any
  aVideo!: any
  tagVideo!: any
  activeSubtitleStyle = 'BASIC'
  currentSubtitleObject: any = {}
  currentTagId: any = null
  overlayObject: any = null
  captionObject: any = null
  bgVideoObject: any = null

  font: string = 'Inter'
  activeColor: string = 'color1'
  displayTextColorPicker: boolean = false
  displayEffectColorPicker: boolean = false
  textColorValue = '#FFFFFF'
  effectColorValue = '#FFFFFF'
  textPosition = 'MIDDLE'
  textSize: number = 30

  // text-format
  formatBold: boolean = false
  formatItalic: boolean = false
  formatUnderline: boolean = false
  formatStrikethrough: boolean = false

  //   Scale
  initialCanvasSize: any = {}
  canvasScaleFactor = 3.2

  //   slider
  startTimeSec: number = 0
  endTimeSec: number = 0
  label!: number

  created() {
    this.initialCanvasSize = this.calcSize(this.orientation)
    this.endTimeSec = this.audio.durationMillis / 1000
    if (this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]) {
      this.startTimeSec = this.selectedEpisodeSettings[this.ytModalId].startTimeSec
      this.endTimeSec = this.selectedEpisodeSettings[this.ytModalId].endTimeSec
    }
  }

  async mounted() {
    const ref = this.$refs.canvas
    this.canvasContext = new fabric.Canvas(ref, {
      preserveObjectStacking: true,
      stateful: true,
      uniScaleKey: '',
      selection: false,
    })

    this.canvasContext.setWidth(this.canvasSize.width)
    this.canvasContext.setHeight(this.canvasSize.height)

    this.artBoardCtx = new fabric.Rect({
      left: 0,
      top: 0,
      width: this.canvasSize.width,
      height: this.canvasSize.height,
      absolutePositioned: true,
      fill: '#FFF',
      hasControls: true,
      typeThing: 'none',
      transparentCorners: false,
      borderColor: '#0E98FC',
      cornerColor: '#0E98FC',
      cursorWidth: 1,
      cursor: 'pointer',
      selectable: false,
      cursorDuration: 1,
      cursorDelay: 250,
      id: 'artboard',
    })

    // Add  Clip canvas to the artboard
    this.canvasContext.clipPath = this.artBoardCtx
    this.artBoardCtx.set('fill', this.color1)
    this.canvasContext.add(this.artBoardCtx)

    this.canvasContext.renderAll()

    this.canvasContext.on('text:changed', () => {
      var linewidth =
        this.canvasContext.getActiveObject().__lineWidths[this.canvasContext.getActiveObject().__lineWidths.length - 1]
      if (!isNaN(linewidth) && linewidth + 40 > this.canvasContext.getActiveObject().width) {
        this.canvasContext.getActiveObject().set('width', linewidth + 40)
        this.canvasContext.renderAll()
      }
    })

    await this.initOverlayObject()
    await this.overlayTag()
    this.changeTextObject()
  }

  //   player related code start
  get audio() {
    return this.$store.getters.audio(this.trackUid)
  }

  get sliderValue() {
    return this.currentTime
  }

  set sliderValue(value: number) {
    this.$emit('scroll-timeline', value)
  }

  get formattedCurrentTime() {
    return secondsToMmss(this.currentTime)
  }

  get formattedTotalTime() {
    return secondsToMmss(Math.ceil(this.endTimeSec ? this.endTimeSec : this.audio.durationMillis / 1000))
  }

  get options() {
    return { ...sliderOPtions, piecewise: true }
  }

  togglePlay() {
    this.$emit('toggle-play')
  }

  replay() {
    this.$emit('scroll-timeline', 0)
    this.videoE.currentTime = 0
  }

  dragend(index: any) {
    this.$emit('scroll-timeline', index.currentValue)
    this.videoE.currentTime = index.currentValue
  }

  callback(value: any) {
    this.videoE.currentTime = value
  }
  //   player code end

  get canvasSize() {
    return this.calcSize(this.orientation)
  }

  get objectScale() {
    return this.canvasSize.width / this.initialCanvasSize.width
  }

  get color1() {
    return new fabric.Gradient({
      type: 'linear',
      gradientUnits: 'pixels', // or 'percentage'
      coords: { x1: 0, y1: this.canvasSize.height / 2, x2: this.canvasSize.width, y2: 0 },
      colorStops: [
        { offset: 0, color: '#B0C8D1' },
        { offset: 1, color: '#3A4051' },
      ],
    })
  }

  get textStyle() {
    return this.selectedTemplate.enum
      ? getTemplateStyle(this.selectedTemplate.enum, this.canvasSize)
      : getTemplateStyle(this.selectedTemplate.template, this.canvasSize, this.selectedTemplate)
  }

  get tagLabel() {
    let tagLabel = []
    for (let i = 0; i < this.tagsInTrack.length; i++) {
      let currentTag = this.tagsInTrack[i]
      let start = Math.floor(currentTag.offsetMillis / 1000)
      tagLabel.push(start)
    }
    return tagLabel
  }

  get tags() {
    // transform tags to handle collision
    let transformedTags = []

    for (let i = 0; i < this.tagsInTrack.length; i++) {
      let currentTag = this.tagsInTrack[i]
      let start = currentTag.offsetMillis
      let end = currentTag.offsetMillis + currentTag.durationMillis

      // If not last tag
      if (i < this.tagsInTrack.length - 1) {
        let nextTag = this.tagsInTrack[i + 1]
        if (currentTag.durationMillis > nextTag.offsetMillis - currentTag.offsetMillis) {
          end = nextTag.offsetMillis
        }
      }
      const tagVideo = get(currentTag, 'tag.video.originalUrl', '')
      const tagImage = get(currentTag, 'tag.image.url', '')

      transformedTags.push({ tagId: currentTag.tagId, start: start, end: end, image: tagImage, video: tagVideo })
    }
    return transformedTags
  }

  @Watch('orientation')
  handleOrientationChange(curr: string, prev: string) {
    this.dynamicScale(prev)
    //@ts-ignore
    this.$nextTick(() => this.$refs['slider'].refresh())
  }

  @Watch('currentTime')
  handleCurrentTime(newTime: number) {
    this.overlayTag()
    this.changeTextObject()
    if (Math.abs(newTime - this.videoE.currentTime) > 0.5) {
      this.videoE.currentTime = newTime
    }
  }

  @Watch('selectedTemplate')
  handleSelectedTemplate() {
    this.changeTextObject()
  }
  @Watch('subtitlesList')
  handleSubtitleListChange() {
    this.changeTextObject()
  }
  @Watch('isPlaying')
  onTogglePlay(curVal: any, oldVal: any) {
    if (curVal === true) {
      this.videoE && this.videoE.play()
      this.tagVideo && this.tagVideo.play()
    } else {
      this.videoE && this.videoE.pause()
      this.tagVideo && this.tagVideo.pause()
    }

    if (curVal === false && this.currentTime === 0) {
      if (this.videoE) {
        this.videoE.currentTime = 0
        this.videoE.pause()
      }
      if (this.tagVideo) {
        this.tagVideo.currentTime = 0
        this.tagVideo.pause()
      }
    }
  }

  calcSize(orientation: string) {
    return orientation === 'LANDSCAPE'
      ? { width: 1920 * (1 / this.canvasScaleFactor), height: 1080 * (1 / this.canvasScaleFactor) }
      : orientation === 'SQUARE'
      ? { width: 1080 * (1 / this.canvasScaleFactor), height: 1080 * (1 / this.canvasScaleFactor) }
      : { width: 720 * (1 / this.canvasScaleFactor), height: 1080 * (1 / this.canvasScaleFactor) }
  }

  changeTextObject() {
    const currentSubtitleObject = this.subtitlesList.filter((obj: any) => {
      const milliSeconds = this.currentTime * 1000
      return milliSeconds >= obj.start && milliSeconds <= obj.end
    })[0]

    if (currentSubtitleObject === undefined) {
      this.captionObject.set('opacity', 0)
      this.canvasContext.renderAll()
    }
    if (this.currentSubtitleObject !== currentSubtitleObject) {
      this.activeSubtitleStyle = this.selectedTemplate?.enum || this.selectedTemplate.template

      if (this.captionObject) {
        this.captionObject.set('opacity', 1)
        this.captionObject.set({
          text: currentSubtitleObject.text,
        })
        this.canvasContext.bringToFront(this.captionObject)
        this.canvasContext.renderAll()
      }
    }
    this.currentSubtitleObject = currentSubtitleObject
  }

  async initOverlayObject() {
    //background Video
    if (this.backgroundVideo) {
      this.bgVideoObject = new fabric.Image(null, {
        left: 0,
        top: 0,
        width: this.canvasContext.width,
        height: this.canvasContext.height,
        selectable: false,
      })
      this.canvasContext.add(this.bgVideoObject)

      const videoE: any = await this.getBgVideoElement(this.backgroundVideo)
      this.bgVideoObject.setElement(videoE)

      let that = this,
        animationFrameId: number

      this.bgVideoObject.setCoords()
      this.bgVideoObject.scaleToWidth(this.canvasContext.get('width'))
      this.bgVideoObject.scaleToHeight(this.canvasContext.get('height'))
      this.bgVideoObject.center()

      function render() {
        that.canvasContext.renderAll()
        animationFrameId = requestAnimationFrame(render)
      }

      // Start the animation frame loop
      animationFrameId = requestAnimationFrame(render)

      // Listen for the 'pause' event on the video element
      videoE.addEventListener('pause', () => {
        window.cancelAnimationFrame(animationFrameId)
      })

      videoE.addEventListener('stop', () => {
        window.cancelAnimationFrame(animationFrameId)
      })

      videoE.addEventListener('play', () => {
        // Restart the animation frame loop
        animationFrameId = requestAnimationFrame(render)
      })

      this.canvasContext.renderAll()
    }

    //overlay tag
    this.overlayObject = new fabric.Image(null, {
      left: 0,
      top: 0,
      width: this.canvasContext.width,
      height: this.canvasContext.height,
      selectable: false,
    })
    this.canvasContext.add(this.overlayObject)

    //captions
    this.captionObject = new fabric.Textbox('', {
      ...this.textStyle,
      scaleX: this.objectScale,
      scaleY: this.objectScale,
    })
    this.canvasContext.add(this.captionObject)
  }

  async overlayTag() {
    const currentTag: any = this.tags.find((tag) => {
      return this.currentTime * 1000 >= tag.start && this.currentTime * 1000 <= tag.end
    })

    if (!currentTag && currentTag !== this.currentTagId) {
      // No current tag found, use audiogram or cover img
      if (this.backgroundVideo) {
        this.clearOverlay()
      } else if (this.audiogramVideo) {
        await this.updateOverlayContent({ video: this.audiogramVideo, image: null })
      } else if (this.audiogramImg) {
        await this.updateOverlayContent({ image: this.audiogramImg, video: null })
      } else if (this.coverImage) {
        await this.updateOverlayContent({ image: this.coverImage, video: null })
      }
    } else if (currentTag && currentTag.tagId !== this.currentTagId) {
      // New tag found, update the overlay content
      await this.updateOverlayContent(currentTag)
    }
    this.currentTagId = currentTag ? currentTag.tagId : undefined
  }

  clearOverlay() {
    this.overlayObject.set('opacity', 0)
    this.canvasContext.renderAll()
  }

  scaleOverlayContent() {
    const imageAspectRatio = this.overlayObject.width / this.overlayObject.height

    let scaleX = this.canvasSize.width / this.overlayObject.width
    let scaleY = this.canvasSize.height / this.overlayObject.height

    if (imageAspectRatio > 1) {
      scaleX = this.canvasSize.width / this.overlayObject.width
      scaleY = scaleX // Maintain aspect ratio
    } else {
      scaleY = this.canvasSize.height / this.overlayObject.height
      scaleX = scaleY // Maintain aspect ratio
    }

    this.overlayObject.scaleX = scaleX
    this.overlayObject.scaleY = scaleY
  }

  async updateOverlayContent(tag: any) {
    this.overlayObject && this.overlayObject.set('opacity', 1)
    if (tag.video) {
      // cleanup
      if (this.tagVideo) this.tagVideo = null
      const videoE: any = await this.getVideoElement(tag.video)
      this.overlayObject.setElement(videoE)

      let that = this,
        animationFrameId: number

      this.scaleOverlayContent()
      this.overlayObject.setCoords()
      this.overlayObject.center()

      function render() {
        that.canvasContext.renderAll()
        animationFrameId = requestAnimationFrame(render)
      }

      // Start the animation frame loop
      animationFrameId = requestAnimationFrame(render)

      // Listen for the 'pause' event on the video element
      videoE.addEventListener('pause', () => {
        window.cancelAnimationFrame(animationFrameId)
      })

      videoE.addEventListener('stop', () => {
        window.cancelAnimationFrame(animationFrameId)
      })

      videoE.addEventListener('play', () => {
        // Restart the animation frame loop
        animationFrameId = requestAnimationFrame(render)
      })
      videoE.play()

      this.canvasContext.bringToFront(this.overlayObject)
      this.canvasContext.renderAll()
    } else if (tag.image) {
      // cleanup/ garbage collection
      if (this.tagVideo) this.tagVideo = null

      // Show the image in the overlay
      this.overlayObject.setSrc(tag.image, () => {
        this.scaleOverlayContent()
        this.overlayObject.setCoords()
        this.overlayObject.center()
        // this.canvasContext.bringToFront(this.overlayObject)
        this.canvasContext.renderAll()
      })
    }
  }

  getVideoElement(url: any) {
    return new Promise((resolve) => {
      this.tagVideo = document.createElement('video')
      this.tagVideo.muted = true
      this.tagVideo.autoplay = false
      this.tagVideo.loop = true
      this.tagVideo.crossOrigin = 'anonymous'
      let source = document.createElement('source')
      source.src = url
      source.id = 'tag-video'
      source.type = 'video/mp4'
      this.tagVideo.appendChild(source)
      this.tagVideo.addEventListener('loadedmetadata', () => {
        this.tagVideo.width = this.tagVideo.videoWidth
        this.tagVideo.height = this.tagVideo.videoHeight
        resolve(this.tagVideo)
      })
    })
  }

  getBgVideoElement(url: any) {
    return new Promise((resolve) => {
      this.videoE = document.createElement('video')
      this.videoE.muted = true
      this.videoE.autoplay = false
      this.videoE.loop = true
      this.videoE.crossOrigin = 'anonymous'
      let source = document.createElement('source')
      source.src = url
      source.id = 'bg-video'
      source.type = 'video/mp4'
      this.videoE.appendChild(source)
      this.videoE.addEventListener('loadedmetadata', () => {
        this.videoE.width = this.videoE.videoWidth
        this.videoE.height = this.videoE.videoHeight
        resolve(this.videoE)
      })
    })
  }

  dynamicScale(orientation: string) {
    this.canvasContext.setHeight(this.canvasSize.height)
    this.canvasContext.setWidth(this.canvasSize.width)

    this.canvasContext.getObjects().forEach((obj: any) => {
      const ratioX = this.canvasSize.width / this.calcSize(orientation).width
      const ratioY = this.canvasSize.height / this.calcSize(orientation).height

      obj.scaleX = ratioX * obj.scaleX || 1
      obj.scaleY = (obj.id === 'artboard' ? ratioY : ratioX) * obj.scaleY || 1
      obj.left = ratioX * obj.left
      obj.top = ratioY * obj.top

      if (['bg-video'].includes(obj.id)) {
        obj.center()
      }

      // Fix scaling
      if (obj.id === 'bg-video') {
        if (obj.width > obj.height) {
          obj.scaleToWidth(this.canvasContext.get('width'))
        } else {
          if (obj.width == obj.height && this.orientation == 'PORTRAIT') {
            obj.scaleToWidth(this.canvasContext.get('width'))
          } else {
            obj.scaleToHeight(this.canvasContext.get('height'))
          }
        }
        obj.center()
      }

      obj.setCoords()
    })

    this.canvasContext.renderAll()
  }

  beforeDestroy() {
    //removes the reference to the video element from memory and makes it eligible for garbage collection
    this.videoE = null
  }

  handleEmbedWeb() {
    this.showIframeEmbed(this.trackUid)
  }
}
